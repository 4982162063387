<template lang="pug">
  v-container(app)
    v-row(justify="center")
      v-col(
        cols="10"
        sm="8"
        md="6"
      )
        v-img(
          :src="notfound"
          alt="Not found"
        )
      v-col(
        cols="12"
        sm="10"
        md="8"
      ).text-center
        h2.secondary--text Oops, sorry. It seems that page doesn't exist anymore
</template>

<script>
import notfound from '@/assets/mmi-not-found-404.png';

export default {
  data () {
    return {
      notfound
    };
  }
};
</script>